import React from 'react'
import LayoutGlobal from '../components/LayoutGlobal'
import Seo from '../components/Seo'

export default function Dziekuje() {
  return (
    <LayoutGlobal>

      <Seo 
        title="Wiadomość została wysłana"
        description="Dziękuję za Twoją wiadomość. Skontaktuję się z Tobą w ciagu 24 godzin."
        robots="noindex"
      />

      <div className='page_box'>
        
        <h1 className='page_h1'>Wiadomość została wysłana!</h1>

      </div>
    </LayoutGlobal>
  )
}